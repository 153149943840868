import React, { useState } from "react";
import {
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLoading,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  IonInput,
  IonTextarea,
  IonPicker,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  logoutUser,
  resetPassword,
  changeDisplayName,
} from "../firebaseconfig";
import { pencil, arrowBack } from "ionicons/icons";
import {
  checkIfFirstLogin,
  setBusinessInformation,
  getBusinessInformation,
} from "../firestoreconfig";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { getLocation } from "../geolocation";
import { toast } from "../toast";
import { PickerColumn } from "@ionic/core";
import { coords, MyLocation, BusInfo } from "../myTypes";
import AboutContent from "./aboutContent";

const Profile: React.FC = () => {
  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    checkLogin();
    setRenderIndex(renderIndex + 1);
  }

  const CategoryColumn = {
    name: "Business Category",
    options: [
      { text: "Shop", value: "Shop" },
      { text: "Salon", value: "Salon" },
      { text: "Clinic", value: "Clinic" },
      { text: "Restaurant", value: "Restaurant" },
      { text: "Service Center", value: "Service Center" },
      { text: "Workshop", value: "Workshop" },
    ],
  } as PickerColumn;

  //mapbox
  const [locationString, setLocationString] = useState<string>("");
  const [locationCoords, setLocationCoords] = useState<coords>();
  const [lastLocationString, setLastLocationString] = useState<string>("");
  const [locationSuggest, setLocationSuggest] = useState<Array<MyLocation>>([]);

  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
  });

  function geocode() {
    if (Math.abs(locationString.length - lastLocationString.length) > 2) {
      let str = locationString.replace(";", "");
      setLastLocationString(str);
      if (str.trim() !== "") {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            let response: Array<MyLocation> = [];
            JSON.parse(this.responseText).features.forEach((e: any) => {
              response.push({
                name: e.place_name,
                lat: e.center[1],
                lng: e.center[0],
              });
            });
            setLocationSuggest(response);
          }
        };
        xhttp.open(
          "GET",
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            str +
            ".json?limit=5&country=IN&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
          true
        );
        xhttp.send();
      }
    }
  }

  async function getCurrentLocation() {
    await getLocation().then(function (loc) {
      if (loc) {
        toast("Detected current location successfully.", 2000, "success");
        setLocationCoords(loc);
        reverseGeocode(loc);
      } else {
        toast("Could not detect location.", 2000, "danger");
      }
    });
  }

  async function reverseGeocode(loc: coords) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        setLocationString(JSON.parse(this.responseText).features[0].place_name);
      }
    };
    xhttp.open(
      "GET",
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        loc?.lng +
        "," +
        loc?.lat +
        ".json?access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
      true
    );
    xhttp.send();
  }

  async function onlyGeocode() {
    let str = locationString.replace(";", "");
    if (str.trim() !== "") {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          JSON.parse(this.responseText).features.forEach((e: any) => {
            setLocationCoords({
              lat: e.center[1],
              lng: e.center[0],
            });
          });
        }
      };
      xhttp.open(
        "GET",
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          str +
          ".json?limit=1&country=IN&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
        true
      );
      xhttp.send();
    }
  }

  //mapbox-end

  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  async function checkLogin() {
    const isExisting = await checkIfFirstLogin();
    setIsFirstLogin(!isExisting);
    if (isExisting) {
      const busInfo = await getBusinessInformation();
      if (busInfo) {
        setBusDescription(busInfo.description);
        setBusCat(busInfo.category);
        setLocationCoords(busInfo.location.coords);
        setLocationString(busInfo.location.name);
        setTelNumber(busInfo.phone);
        setCurrentBusInfo({
          description: busInfo.description,
          category: busInfo.category,
          locationCoords: busInfo.location.coords,
          locationString: busInfo.location.name,
          phone: busInfo.phone
        });
      }
    }
  }

  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.data);
  const history = useHistory();

  const [username, setUsername] = useState<string>(user.displayName);
  const [newName, setNewName] = useState<string>(user.displayName);

  const [showCategoryPicker, setShowCategoryPicker] = useState<boolean>(false);
  const [busCat, setBusCat] = useState<string>("");
  const [busDescription, setBusDescription] = useState<string>("");
  const [telNumber, setTelNumber] = useState<string>("");

  const [currentBusInfo, setCurrentBusInfo] = useState<BusInfo>({
    description: "",
    category: "",
    locationCoords: {
      lat: 0,
      lng: 0
    },
    locationString: "",
    phone: ""
  });

  async function logoutResponse() {
    await logoutUser();
    history.replace("/");
  }

  function changePassword() {
    setBusy(true);
    const res = resetPassword(user.email);
    if (res) {
    } else {
    }
    setBusy(false);
  }

  function changeName() {
    setBusy(true);
    const res = changeDisplayName(newName);
    if (res) {
      setShowNameChangeModal(false);
      setUsername(newName);
    } else {
    }
    setBusy(false);
  }

  async function saveBusinessInfo() {
    if (
      busCat === "" ||
      locationString === "" ||
      locationCoords == null ||
      busDescription === "" ||
      telNumber === ""
    ) {
      toast("All fields are mandatory.", 2000, "danger");
    } else {
      toast("Saving Information.", 2000, "primary");
      const response = await setBusinessInformation(
        locationString,
        locationCoords,
        busDescription,
        busCat,
        telNumber,
        isFirstLogin
      );
      if (response) {
        toast("Information saved.", 2000, "success");
        setIsFirstLogin(false);
        setShowInfoModal(false);
        setCurrentBusInfo({
          description: busDescription,
          category: busCat,
          locationCoords: locationCoords,
          locationString: locationString,
          phone: telNumber
        });
      } else {
        toast("Could not save information.", 2000, "danger");
      }
    }
  }

  const [showAboutModal, setShowAboutModal] = useState(false);

  return (
    <IonContent>
      <IonModal isOpen={showNameChangeModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowNameChangeModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Change Business Name</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>Enter your new business name below.</p>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Business Name</IonLabel>
              <IonInput
                value={newName}
                type="text"
                onIonChange={(e: any) => setNewName(e.target.value)}
              />
            </IonItem>
          </IonList>
          <IonButton onClick={changeName} color="primary">
            Confirm Name
          </IonButton>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showAboutModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowAboutModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">About</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
			<AboutContent/>
		</IonContent>
      </IonModal>
      <IonModal isOpen={isFirstLogin || showInfoModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          {!isFirstLogin && (
            <IonButton
              slot="start"
              onClick={() => setShowInfoModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
          )}
          <IonTitle className="eina">Set Business Information</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          {isFirstLogin ? (
            <p>
              Welcome to <b>LocalQueue Vendor</b>! Fill in your business'
              information below to get started.
            </p>
          ) : (
            <p>Enter your new information below.</p>
          )}
          <IonList>
            <IonItem>
              <IonLabel position="floating">Business Location</IonLabel>
              <IonInput
                value={locationString}
                onIonChange={(e: any) => {
                  setLocationString(e.target.value);
                  geocode();
                }}
                type="text"
                onIonBlur={() => {
                  if (locationString.trim() !== "" && locationCoords == null) {
                    onlyGeocode();
                  }
                }}
              />
            </IonItem>
            {locationSuggest.map((place, i) => (
              <IonItem
                button={true}
                detail={true}
                onClick={() => {
                  setLocationString(place.name);
                  setLocationCoords({
                    lat: place.lat,
                    lng: place.lng,
                  });
                }}
                key={i}
              >
                {place.name}
              </IonItem>
            ))}
            <IonItem>
              {locationCoords ? (
                <Map
                  // eslint-disable-next-line react/style-prop-object
                  style={"mapbox://styles/mapbox/streets-v9"}
                  containerStyle={{
                    height: "200px",
                    width: "100%",
                  }}
                  center={[
                    Number(locationCoords.lng),
                    Number(locationCoords.lat),
                  ]}
                  zoom={[15]}
                >
                  <Marker
                    coordinates={[+locationCoords.lng!, +locationCoords.lat!]}
                    anchor="bottom"
                  >
                    <img
                      alt=""
                      src={require("../images/markers/primary.png")}
                    />
                  </Marker>
                </Map>
              ) : null}
            </IonItem>

            <IonItem
              button={true}
              detail={true}
              onClick={() => getCurrentLocation()}
            >
              Use Current Location
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <IonInput
                value={telNumber}
                onIonChange={(e: any) => {
                  setTelNumber(e.target.value);
                  geocode();
                }}
                type="tel"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Business Description</IonLabel>
              <IonTextarea
                placeholder="A brief description of your work."
                value={busDescription}
                onIonChange={(e: any) => setBusDescription(e.target.value)}
              />
            </IonItem>
            <IonItem
              button={true}
              detail={true}
              onClick={() => setShowCategoryPicker(true)}
            >
              Select Business Category
            </IonItem>
            {busCat ? (
              <IonItem>
                <b>{busCat}</b>
              </IonItem>
            ) : null}
          </IonList>
          <IonButton
            color="primary"
            onClick={() => {           saveBusinessInfo();
            }}
          >
            Save Information
          </IonButton>
        </IonContent>
      </IonModal>
      <IonPicker
        isOpen={showCategoryPicker}
        columns={[CategoryColumn]}
        backdropDismiss={false}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setShowCategoryPicker(false);
            },
          },
          {
            text: "Confirm",
            handler: (value) => {
              setBusCat(value["Business Category"].value);
              setShowCategoryPicker(false);
            },
          },
        ]}
      ></IonPicker>
      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonList inset={true}>
        <IonItem>
          <h2>{username}</h2>
          <IonButton slot="end" onClick={() => setShowNameChangeModal(true)}>
            <IonLabel>Edit</IonLabel>
            <IonIcon icon={pencil}></IonIcon>
          </IonButton>
        </IonItem>
        <IonItem>{user.email}</IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Location</h2>
            <p>{currentBusInfo.locationString}</p>
            {locationCoords ? (
              <Map
                // eslint-disable-next-line react/style-prop-object
                style={"mapbox://styles/mapbox/streets-v9"}
                containerStyle={{
                  height: "200px",
                  width: "100%",
                }}
                center={[
                  Number(currentBusInfo.locationCoords.lng),
                  Number(currentBusInfo.locationCoords.lat),
                ]}
                zoom={[15]}
              >
                <Marker
                  coordinates={[+currentBusInfo.locationCoords.lng!, +currentBusInfo.locationCoords.lat!]}
                  anchor="bottom"
                >
                  <img alt="" src={require("../images/markers/primary.png")} />
                </Marker>
              </Map>
            ) : null}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Phone Number</h2>
            <p>{currentBusInfo.phone}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Description</h2>
            <p>{currentBusInfo.description}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Business Category</h2>
            <p>{currentBusInfo.category}</p>
          </IonLabel>
        </IonItem>
        <IonItem
          button={true}
          onClick={() => {
            setShowInfoModal(true);
          }}
          detail={true}
        >
          <IonLabel className="comfortaa">Edit Information</IonLabel>
        </IonItem>
        <IonItem button={true} onClick={changePassword} detail={true}>
          <IonLabel className="comfortaa">Change Password</IonLabel>
        </IonItem>
        <IonItem button={true} onClick={logoutResponse} detail={true}>
          <IonLabel color="danger" className="comfortaa">
            Log Out
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => setShowAboutModal(true)} button detail>
          About
        </IonItem>
        <IonItem onClick={() => window.open("mailto:info@localqueue.in", "_system")} button detail>
          Contact Support
        </IonItem>
      </IonList>
    </IonContent>
  );
};

export default Profile;
