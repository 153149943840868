import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { coords, Notif, Queue, service } from "./myTypes";
import { clientDB } from "./firebaseconfig_Client";

const db = firebase.firestore();

function randomString(length: number) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}

export { db };

export async function checkIfFirstLogin() {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .get()
    .then(function (doc) {
      return doc.exists;
    })
    .catch(function (e) {});
}

export async function setBusinessInformation(
  locName: string,
  locCoords: coords,
  description: string,
  category: string,
  phone: string,
  isFirstLogin: boolean
) {
  let data = {
    location: {
      name: locName,
      coords: locCoords,
    },
    description: description,
    category: category,
    name: firebase.auth().currentUser!.displayName,
    phone: phone,
    email: firebase.auth().currentUser!.email,
  };
  if (isFirstLogin) Object.assign(data, { serviceData: {} });
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .set(data, { merge: true })
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export async function getBusinessInformation() {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .get()
    .then(function (doc) {
      return doc.data();
    })
    .catch(function (e) {});
}

export async function getServices() {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .get()
    .then(function (doc) {
      let serviceData = Object({});
      if (doc.exists) {
        serviceData = doc.data()?.serviceData;
      }
      return serviceData;
    })
    .catch(function (e) {});
}

export async function updateServiceData(data: object) {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .set(
      {
        serviceData: data,
      },
      { merge: true }
    )
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export async function respondToRequest(
  didAccept: boolean,
  message: string,
  notif: Notif
) {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .collection("notifications")
    .doc(notif.id)
    .set(
      { active: false, outcome: didAccept ? "accept" : "reject" },
      { merge: true }
    )
    .then(() => {
      return clientDB
        .collection("users")
        .doc(notif.userData.id)
        .collection("notifications")
        .add({
          type: "service",
          service: notif.service,
          serviceID: notif.serviceID,
          didAccept: didAccept,
          message: message,
          vendor: {
            name: firebase.auth().currentUser!.displayName,
            id: firebase.auth().currentUser!.uid,
          },
          time: firebase.firestore.Timestamp.fromDate(new Date()),
          active: true,
        })
        .then(() => {
          if (didAccept) {
            return db
              .collection("vendors")
              .doc(firebase.auth().currentUser!.uid)
              .update({
                ["serviceData." +
                notif.serviceID +
                ".queue"]: firebase.firestore.FieldValue.increment(1),
              })
              .then(() => {
                return db
                  .collection("queues")
                  .doc(firebase.auth().currentUser!.uid + "_" + notif.serviceID)
                  .collection("customers")
                  .doc(notif.serviceID + randomString(9))
                  .set({
                    userData: notif.userData,
                    serviceData: notif.service,
                    time: firebase.firestore.Timestamp.fromDate(new Date()),
                    isQueued: true,
                  })
                  .then(() => {
                    return true;
                  });
              });
          } else {
            return true;
          }
        });
    });
}

export async function getQueueData(serviceID: number) {
  return db
    .collection("queues")
    .doc(firebase.auth().currentUser!.uid + "_" + serviceID)
    .collection("customers")
    .where("isQueued", "==", true)
    .orderBy("time", "asc")
    .get()
    .then(function (querySnapshot) {
      var queueArray: Queue[] = [];
      querySnapshot.forEach(function (doc) {
        let tempObj = doc.data() as Queue;
        tempObj.id = doc.id;
        queueArray.push(tempObj);
      });
      return queueArray;
    });
}

export async function addToQueueManually(
  serviceID: number,
  serviceData: service,
  cutomerName: string
) {
  var docData = {
    userData: {
      name: cutomerName,
    },
    serviceData: serviceData,
    time: firebase.firestore.Timestamp.fromDate(new Date()),
    isQueued: true,
    isManual: true,
  };
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .update({
      ["serviceData." +
      serviceID +
      ".queue"]: firebase.firestore.FieldValue.increment(1),
    })
    .then(() => {
      return db
        .collection("queues")
        .doc(firebase.auth().currentUser!.uid + "_" + serviceID)
        .collection("customers")
        .add(docData)
        .then((doc) => {
          return docData as Queue;
        });
    });
}

export async function proceedQueue(queue: Queue, serviceID: string) {
  return db
    .collection("vendors")
    .doc(firebase.auth().currentUser!.uid)
    .update({
      ["serviceData." +
      serviceID +
      ".queue"]: firebase.firestore.FieldValue.increment(-1),
    })
    .then(() => {
      return db
        .collection("queues")
        .doc(firebase.auth().currentUser!.uid! + "_" + serviceID!)
        .collection("customers")
        .doc(queue.id)
        .set({ isQueued: false }, { merge: true })
        .then(() => {
          if (queue.isManual) {
            return true;
          } else {
            return clientDB
              .collection("users")
              .doc(queue.userData.id)
              .collection("notifications")
              .add({
                type: "turn",
                service: queue.serviceData,
                serviceID: serviceID,
                vendor: {
                  name: firebase.auth().currentUser!.displayName,
                  id: firebase.auth().currentUser!.uid,
                },
                time: firebase.firestore.Timestamp.fromDate(new Date()),
              })
              .then(() => {
                return true;
              });
          }
        });
    });
}

export async function getCustomerInfo(code: string) {
  var serviceID = code[0];
  return db
    .collection("queues")
    .doc(firebase.auth().currentUser!.uid + "_" + serviceID)
    .collection("customers")
    .doc(code)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
}
