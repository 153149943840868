import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import React from "react";
import "../theme/HomePage.css";

const Home: React.FC = () => {
  return (
    <IonPage className="pageContainer">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="eina">LocalQueue Vendor</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding container">
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <img alt="" src={require("../images/icon.png")} width={100} />
            <IonCardTitle className="eina">LocalQueue Vendor</IonCardTitle>
            <IonCardSubtitle>
              Give power to your business by creating virtual queues and join
              the new trend of giving customers the safety they want and the
              comfort they seek.
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonButton routerLink="/tour" color="warning">
              Launch Tour
            </IonButton>
            <br/>

            <a href="https://play.google.com/store/apps/details?id=in.localqueue.vendor&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                style={{width: "200px", maxWidth: "75%"}}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Start Using LocalQueue Vendor</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonGrid className="ion-text-center">
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/login" color="primary">
                    Login
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/signup" color="tertiary">
                    SignUp
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Pricing</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonGrid>
                  <h2>
                    <span
                      style={{ color: "red", textDecoration: "line-through" }}
                    >
                      <span className="eina" style={{ color: "black" }}>
                        Rs. 399
                      </span>{" "}
                      <span style={{ color: "black" }}>per month</span>
                    </span>
                    <h1 className="eina" style={{ color: "green" }}>
                      Join for Free
                    </h1>
                    <h4>Limited Time Offer</h4>
                    <span className="small-text">
                      Subject to payment after offer period ends.
                    </span>
                  </h2>
                </IonGrid>
              </IonRow>
              <IonRow>
                <IonCol>
                  <br />
                  <img
                    alt=""
                    src={require("../images/misc/discount.png")}
                    style={{ width: "50%" }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
