export function toast(
  message: string,
  duration = 2000,
  color = "primary",
  buttons = [{}]
) {
  const toast = document.createElement("ion-toast");
  toast.message = message;
  toast.duration = duration;
  toast.color = color;
  toast.buttons = buttons;
  document.body.appendChild(toast);
  return toast.present();
}
