import React from "react";
import { IonPage, IonContent } from "@ionic/react";

const AboutContent: React.FC = () => {
  return (
    <>
      <div className="ion-text-center">
        <img alt="" src={require("../images/icon.png")} width={100} />
        <h2 className="eina">LocalQueue Vendor</h2>
        <p>
          Give power to your business by creating virtual queues and join the
          new trend of giving customers the safety they want and the comfort
          they seek.
        </p>
      </div>
      <h2>Credits</h2>
      <ul>
        <li>
          Illustrations by <a href="https://icons8.com">Icons8</a>
        </li>
        <li>
          QR codes by <a href="http://goqr.me/">goQR.me</a>
        </li>
        <li>
          Icons made by{" "}
          <a href="https://www.flaticon.com/authors/icongeek26">Icongeek26</a>, <a href="https://www.flaticon.com/authors/srip">srip</a>,{" "}
          <a href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>,{" "}
          <a href="https://www.flaticon.com/authors/eucalyp">Eucalyp</a>,{" "}
          <a href="https://www.flaticon.com/authors/vitaly-gorbachev">
            Vitaly Gorbachev
          </a>{" "}
          and{" "}
          <a href="https://www.flaticon.com/authors/pixel-perfect">
            Pixel Perfect
          </a>{" "}
          from <a href="http://www.flaticon.com/">www.flaticon.com</a>
        </li>
      </ul>
      <h2>Privacy Policy</h2>
      <p>
        Your privacy is important to us. It is LocalQueue's policy to respect
        your privacy regarding any information we may collect from you through
        our app, LocalQueue.
      </p>
      <p>
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.
      </p>
      <p>
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
      </p>
      <p>
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.
      </p>
      <p>
        Our app may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their
        respective privacy policies.
      </p>
      <p>
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.
      </p>
      <p>
        Your continued use of our app will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.
      </p>
      <p>This policy is effective as of 27 June 2020.</p>
      <p>
        <a href="https://getterms.io" title="Generate a free privacy policy">
          Privacy Policy created with GetTerms.
        </a>
      </p>

      <h2>LocalQueue Terms of Service</h2>
      <h3>1. Terms</h3>
      <p>
        By accessing our app, LocalQueue, you are agreeing to be bound by these
        terms of service, all applicable laws and regulations, and agree that
        you are responsible for compliance with any applicable local laws. If
        you do not agree with any of these terms, you are prohibited from using
        or accessing LocalQueue. The materials contained in LocalQueue are
        protected by applicable copyright and trademark law.
      </p>
      <h3>2. Use License</h3>
      <ol type="a">
        <li>
          Permission is granted to temporarily download one copy of LocalQueue
          per device for personal, non-commercial transitory viewing only. This
          is the grant of a license, not a transfer of title, and under this
          license you may not:
          <ol type="i">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained in
              LocalQueue;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transfer the materials to another person or "mirror" the materials
              on any other server.
            </li>
          </ol>
        </li>
        <li>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by LocalQueue at any time. Upon
          terminating your viewing of these materials or upon the termination of
          this license, you must destroy any downloaded materials in your
          possession whether in electronic or printed format.
        </li>
      </ol>
      <h3>3. Disclaimer</h3>
      <ol type="a">
        <li>
          The materials within LocalQueue are provided on an 'as is' basis.
          LocalQueue makes no warranties, expressed or implied, and hereby
          disclaims and negates all other warranties including, without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights.
        </li>
        <li>
          Further, LocalQueue does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of
          the materials on its website or otherwise relating to such materials
          or on any sites linked to LocalQueue.
        </li>
      </ol>
      <h3>4. Limitations</h3>
      <p>
        In no event shall LocalQueue or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        LocalQueue, even if LocalQueue or a LocalQueue authorized representative
        has been notified orally or in writing of the possibility of such
        damage. Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
      </p>
      <h3>5. Accuracy of materials</h3>
      <p>
        The materials appearing in LocalQueue could include technical,
        typographical, or photographic errors. LocalQueue does not warrant that
        any of the materials on LocalQueue are accurate, complete or current.
        LocalQueue may make changes to the materials contained in LocalQueue at
        any time without notice. However LocalQueue does not make any commitment
        to update the materials.
      </p>
      <h3>6. Links</h3>
      <p>
        LocalQueue has not reviewed all of the sites linked to its app and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by LocalQueue of the site. Use of
        any such linked website is at the user's own risk.
      </p>
      <h3>7. Modifications</h3>
      <p>
        LocalQueue may revise these terms of service for its app at any time
        without notice. By using LocalQueue you are agreeing to be bound by the
        then current version of these terms of service.
      </p>
      <h3>8. Governing Law</h3>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of Noida, India and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </p>
      <p>
        <a
          href="https://getterms.io"
          title="Generate a free terms of use document"
        >
          Terms of Use created with GetTerms.
        </a>
      </p>
    </>
  );
};

export default AboutContent;

const AboutContentPage:React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <AboutContent/>
      </IonContent>
    </IonPage>
  );
}

export { AboutContentPage };
