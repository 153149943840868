import "@ionic/pwa-elements";
import { useCamera } from "@ionic/react-hooks/camera";
import {
  CameraResultType,
  CameraSource
} from "@capacitor/core";
const {PNG} = require('pngjs');
const jsqr = require('jsqr');
export function usePhotoGallery() {
  const { getPhoto } = useCamera();

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 100,
    });
    return cameraPhoto;
  };

  return {
    takePhoto,
  };
}

export async function scanQRBase64Png(base64String: string){
    const dataUri = 'data:image/png;base64,' + base64String;
const png = PNG.sync.read(Buffer.from(dataUri.slice('data:image/png;base64,'.length), 'base64'));
const code = jsqr(Uint8ClampedArray.from(png.data), png.width, png.height);
return code;
}
