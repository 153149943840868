import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonPage,
  IonContent,
  IonSpinner,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Dashboard from "./pages/dashboard";
import { getCurrentUser } from "./firebaseconfig";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { useDispatch } from "react-redux";
import { setUserState } from "./redux/actions";
import Slides from "./pages/slides";
import { AboutContentPage } from "./components/aboutContent";

const RoutingSystem: React.FC = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" component={Home} exact={true} />
        <Route path="/login" component={Login} exact />
        <Route path="/Signup" component={Signup} exact />
        <Route path="/dashboard" component={Dashboard} exact />
        <Route path="/tour" component={Slides} exact />
        <Route path="/terms" component={AboutContentPage} exact />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

const SpinningSection: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding ion-text-center">
        <p>Please Wait</p>
        <IonSpinner />
      </IonContent>
    </IonPage>
  );
};

const App: React.FC = () => {
  const [busy, setBusy] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentUser().then((user: any) => {
      if (user) {
        // logged in
        dispatch(setUserState(user));
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/login" ||
          window.location.pathname === "/signup"
        ) {
          window.history.replaceState({}, "", "/dashboard");
        }
      } else {
        // logged out

        if (
          window.location.pathname !== "/tour" &&
          window.location.pathname !== "/terms"
        ) {
          window.history.replaceState({}, "", "/");
        }
      }
      setBusy(false);
    });
  }, [dispatch]);

  return <IonApp>{busy ? <SpinningSection /> : <RoutingSystem />}</IonApp>;
};

export default App;
