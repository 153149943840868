import React, { useState } from "react";
import {
  IonContent,
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonPicker,
  IonTextarea,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonToggle,
} from "@ionic/react";
import {
  add,
  arrowBack,
  pricetag,
  hourglass,
  pencil,
  star,
  starHalf,
  time,
} from "ionicons/icons";
import { PickerColumn } from "@ionic/core";
import { toast } from "../toast";
import { getServices, updateServiceData } from "../firestoreconfig";
import { serviceObj } from "../myTypes";

const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

const Services: React.FC = () => {
  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    updateServices();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [showAddServiceModal, setShowAddServiceModal] = useState<boolean>(
    false
  );
  const [showServiceDurationPicker, setShowServiceDurationPicker] = useState<
    boolean
  >(false);
  const [shouldEditService, setShouldEditService] = useState<boolean>(false);
  const [editServiceKey, setEditServiceKey] = useState<number>(-1);

  const [addServiceName, setAddServiceName] = useState<string>("");
  const [addServicePrice, setAddServicePrice] = useState<string>("");
  const [addServiceDuration, setAddServiceDuration] = useState<string>("");
  const [addServiceDescription, setAddServiceDescription] = useState<string>(
    ""
  );
  const [addServiceIsGeneral, setAddServiceIsGeneral] = useState<boolean>(
    false
  );
  const [addServiceIsDiscounted, setAddServiceIsDiscounted] = useState<boolean>(
    false
  );
  const [addServiceOriginalPrice, setAddServiceOriginalPrice] = useState<
    string
  >("");

  const [serviceData, setServiceData] = useState<serviceObj>({});

  async function updateServices() {
    const data = await getServices();
    if (data) {
      setServiceData(data);
    }
  }

  async function addService() {
    let validationCondition;
    if (addServiceIsGeneral)
      validationCondition =
        addServiceName.trim() === "" || addServiceDescription.trim() === "";
    else
      validationCondition =
        addServiceName.trim() === "" ||
        addServicePrice === "" ||
        addServiceDuration === "" ||
        addServiceDescription.trim() === "" ||
        (addServiceIsDiscounted && addServiceOriginalPrice === "");
    if (validationCondition) {
      toast("All fields are mandatory.", 2000, "danger");
    } else {
      toast("Adding new service.", 2000, "primary");
      let prevData = serviceData;
      var newData: { [k: string]: any } = {};
      newData[Object.keys(prevData).length] = {
        name: addServiceName,
        price: addServicePrice,
        duration: addServiceDuration,
        description: addServiceDescription,
        available: true,
        isGeneral: addServiceIsGeneral,
        isDiscounted: addServiceIsDiscounted,
        originalPrice: addServiceIsDiscounted ? addServiceOriginalPrice : 0,
      };
      let data = { ...prevData, ...newData };
      const response = await updateServiceData(data);
      if (response) {
        toast("Service added.", 2000, "success");
        setShowAddServiceModal(false);
        setAddServiceName("");
        setAddServicePrice("");
        setAddServiceDuration("");
        setAddServiceDescription("");
        setServiceData(data);
        setAddServiceIsGeneral(false);
        setAddServiceIsDiscounted(false);
        setAddServiceOriginalPrice("");
      } else {
        toast("Could not add service.", 2000, "danger");
      }
    }
  }

  async function editServices() {
    const response = await updateServiceData(serviceData);
    if (response) {
      updateServices();
      return true;
    } else {
      return false;
    }
  }

  async function toggleService(toggleType: boolean) {
    const response = await editServices();
    if (response) {
      toast(
        "Service is now " + (toggleType ? "" : "un") + "available.",
        2000,
        "success"
      );
    } else {
      toast("Could not make changes.", 2000, "danger");
    }
  }

  async function editServiceDetails() {
    let validationCondition;
    if (addServiceIsGeneral)
      validationCondition =
        addServiceName.trim() === "" || addServiceDescription.trim() === "";
    else
      validationCondition =
        addServiceName.trim() === "" ||
        addServicePrice === "" ||
        addServiceDuration === "" ||
        addServiceDescription.trim() === "" ||
        (addServiceIsDiscounted && addServiceOriginalPrice === "");
    if (validationCondition) {
      toast("All fields are mandatory.", 2000, "danger");
    } else {
      toast("Making changes.", 2000, "primary");

      serviceData[editServiceKey].name = addServiceName;
      serviceData[editServiceKey].price = +addServicePrice;
      serviceData[editServiceKey].duration = +addServiceDuration;
      serviceData[editServiceKey].description = addServiceDescription;
      serviceData[editServiceKey].isGeneral = addServiceIsGeneral
        ? addServiceIsGeneral
        : false;
      serviceData[editServiceKey].isDiscounted = addServiceIsDiscounted
        ? addServiceIsDiscounted
        : false;
      serviceData[editServiceKey].originalPrice = +addServiceOriginalPrice;

      const response = await editServices();
      if (response) {
        toast("Service updated.", 2000, "success");
        setShowAddServiceModal(false);
        setShouldEditService(false);
        setAddServiceName("");
        setAddServicePrice("");
        setAddServiceDuration("");
        setAddServiceDescription("");
        setEditServiceKey(-1);
        setAddServiceIsGeneral(false);
        setAddServiceIsDiscounted(false);
        setAddServiceOriginalPrice("");
        setEditOnlyDuration(false);
      } else {
        toast("Could not update service.", 2000, "danger");
      }
    }
  }

  const [editOnlyDuration, setEditOnlyDuration] = useState<boolean>(false);

  async function initEditServiceDetails(key: number) {
    setAddServiceName(serviceData[key].name);
    setAddServicePrice("" + serviceData[key].price);
    setAddServiceDuration("" + serviceData[key].duration);
    setAddServiceDescription(serviceData[key].description);
    setAddServiceIsGeneral(serviceData[key].isGeneral);
    setAddServiceIsDiscounted(serviceData[key].isDiscounted);
    setAddServiceOriginalPrice("" + serviceData[key].originalPrice);
    setEditServiceKey(key);
    setShouldEditService(true);
    setShowAddServiceModal(true);
  }

  return (
    <IonContent>
      <IonModal isOpen={showAddServiceModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => {
              setShowAddServiceModal(false);
              setAddServiceName("");
              setAddServicePrice("");
              setAddServiceDuration("");
              setAddServiceDescription("");
              setAddServiceIsGeneral(false);
              setAddServiceIsDiscounted(false);
              setAddServiceOriginalPrice("");
              setEditOnlyDuration(false);
              setShouldEditService(false);
            }}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">
            {shouldEditService === false ? "Add New Service" : (editOnlyDuration === false ? "Edit Service" : "Edit Service Duration")}
          </IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            {shouldEditService === false
              ? "Add a new service to let customers avail your service through LocalQueue."
              : (editOnlyDuration === false ? "Edit the fields below to update your service." : "Edit this service's duration to give a better idea to your customers about how long they might have to wait.")}
          </p>
          <IonList>
            <IonItem hidden={editOnlyDuration}>
              <IonToggle
                checked={addServiceIsGeneral}
                onIonChange={(e) => {
                  setAddServiceIsGeneral(e.detail.checked);
                }}
              />
              <IonLabel className="ion-text-wrap">
                <h2>Is this a General Service?</h2>
                <p>
                  General services like <b>shopping</b> do not have a fixed
                  price or duration.
                </p>
              </IonLabel>
            </IonItem>
            <IonItem hidden={editOnlyDuration}>
              <IonLabel position="floating">Service Name</IonLabel>
              <IonInput
                value={addServiceName}
                type="text"
                onIonChange={(e: any) => setAddServiceName(e.target.value)}
              />
            </IonItem>
            <IonItem hidden={addServiceIsGeneral || editOnlyDuration}>
              <IonLabel position="floating">Service Price (Rupees)</IonLabel>
              <IonInput
                value={addServicePrice}
                type="number"
                onIonChange={(e: any) => setAddServicePrice(e.target.value)}
              />
            </IonItem>

            <IonItem hidden={addServiceIsGeneral || editOnlyDuration}>
              <IonToggle
                checked={addServiceIsDiscounted}
                onIonChange={(e) => {
                  setAddServiceIsDiscounted(e.detail.checked);
                }}
              />
              <IonLabel className="ion-text-wrap">
                <h2>Are you offering a Discount?</h2>
                <p>
                  Let your customers know if you are giving them a discount.
                </p>
              </IonLabel>
            </IonItem>

            <IonItem hidden={addServiceIsGeneral || !addServiceIsDiscounted || editOnlyDuration}>
              <IonLabel position="floating">Original Price (Rupees)</IonLabel>
              <IonInput
                value={addServiceOriginalPrice}
                type="number"
                onIonChange={(e: any) =>
                  setAddServiceOriginalPrice(e.target.value)
                }
              />
            </IonItem>

            <IonItem hidden={editOnlyDuration}>
              <IonLabel position="floating">Service Description</IonLabel>
              <IonTextarea
                value={addServiceDescription}
                onIonChange={(e: any) =>
                  setAddServiceDescription(e.target.value)
                }
                placeholder="What's included in this service, what are it's features, etc."
              />
            </IonItem>
            <IonItem
              button={true}
              detail={true}
              onClick={() => setShowServiceDurationPicker(true)}
              hidden={addServiceIsGeneral}
            >
              <IonLabel className="ion-text-wrap">
                <h2>Select Service Duration</h2>
                <p className="small-text">
                  Approximate time it takes to serve one customer.
                </p>
              </IonLabel>
            </IonItem>
            {addServiceDuration ? (
              <IonItem hidden={addServiceIsGeneral}>
                <b>{addServiceDuration} minutes</b>
              </IonItem>
            ) : null}
          </IonList>

          <IonButton
            onClick={() => {
              shouldEditService === false ? addService() : editServiceDetails();
            }}
            color="primary"
          >
            {editOnlyDuration === false ? "Confirm Details" : "Confirm Duration"}
            
          </IonButton>
        </IonContent>
      </IonModal>
      <IonPicker
        isOpen={showServiceDurationPicker}
        columns={[ServiceDurationColumn]}
        backdropDismiss={false}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              setShowServiceDurationPicker(false);
            },
          },
          {
            text: "Confirm",
            handler: (value) => {
              setAddServiceDuration(value["Service Duration"].value);
              setShowServiceDurationPicker(false);
            },
          },
        ]}
      ></IonPicker>
      <IonButton expand="full" onClick={() => setShowAddServiceModal(true)}>
        <IonIcon icon={add} slot="end"></IonIcon>
        Add Service
      </IonButton>
      <IonList>
        {Object.keys(serviceData)
          .reverse()
          .map((key: any, i) => (
            <IonCard
              key={key}
              className={serviceData[key].available ? "" : "disabledCard"}
            >
              <IonCardHeader>
                <IonItem lines="none">
                  <IonCardTitle>{serviceData[key].name}</IonCardTitle>
                  <IonButton
                    slot="end"
                    onClick={() => initEditServiceDetails(key)}
                  >
                    <IonLabel>Edit</IonLabel>
                    <IonIcon icon={pencil} slot="end"></IonIcon>
                  </IonButton>
                </IonItem>

                <IonCardSubtitle>
                  <IonIcon icon={pricetag}></IonIcon>{" "}
                  {!serviceData[key].isGeneral &&
                  serviceData[key].isDiscounted ? (
                    <span
                      style={{ color: "black", textDecoration: "line-through" }}
                    >
                      <span style={{ color: "red" }}>
                        {"Rs. " + serviceData[key].originalPrice + " "}
                      </span>
                    </span>
                  ) : null}
                  {serviceData[key].isGeneral
                    ? "Price may vary"
                    : "Rs. " + serviceData[key].price}
                </IonCardSubtitle>
                <IonCardSubtitle>
                  <IonIcon icon={hourglass}></IonIcon>{" "}
                  {serviceData[key].isGeneral ? (
                    <span>{"Duration may vary"}</span>
                  ) : (
                    <span>
                      {serviceData[key].duration + " minutes"}
                      <IonButton
                        style={{
                          fontSize: "0.9em",
                          height: "25px",
                          marginLeft: "10px",
                        }}
                        size="small"
                        color="warning"
                        onClick={() => {setEditOnlyDuration(true); initEditServiceDetails(key);}}
                      >
                        <IonLabel>Edit</IonLabel>
                        <IonIcon icon={time} slot="end"></IonIcon>
                      </IonButton>
                    </span>
                  )}
                </IonCardSubtitle>
                {serviceData[key].rating ? (
                  <IonCardSubtitle>
                    {Array(Math.floor(average(serviceData[key].rating))).fill(
                      <IonIcon icon={star} color="warning"></IonIcon>
                    )}
                    {average(serviceData[key].rating) -
                      Math.floor(average(serviceData[key].rating)) >=
                    0.4 ? (
                      <IonIcon icon={starHalf} color="warning"></IonIcon>
                    ) : null}
                  </IonCardSubtitle>
                ) : null}
              </IonCardHeader>
              <IonCardContent>
                <p>
                  <b>{serviceData[key].description}</b>
                </p>
                <p style={{ color: "#dd0000" }}>
                  Current Queue:{" "}
                  <b>{serviceData[key].queue ? serviceData[key].queue : 0}</b>
                </p>
                <br />
                <IonItem lines="none">
                  <IonToggle
                    checked={serviceData[key].available}
                    onIonChange={(e) => {
                      serviceData[key].available = e.detail.checked;
                      toggleService(e.detail.checked);
                    }}
                  />
                  <p>
                    Service {serviceData[key].available ? "" : "Not"} Available
                  </p>
                </IonItem>
              </IonCardContent>
            </IonCard>
          ))}
      </IonList>
    </IonContent>
  );
};

export default Services;

const ServiceDurationColumn = {
  name: "Service Duration",
  options: [
    { text: "5 minutes", value: "5" },
    { text: "10 minutes", value: "10" },
    { text: "15 minutes", value: "15" },
    { text: "20 minutes", value: "20" },
    { text: "25 minutes", value: "25" },
    { text: "30 minutes", value: "30" },
    { text: "35 minutes", value: "35" },
    { text: "40 minutes", value: "40" },
    { text: "45 minutes", value: "45" },
    { text: "50 minutes", value: "50" },
    { text: "55 minutes", value: "55" },
    { text: "60 minutes", value: "60" },
    { text: "65 minutes", value: "65" },
    { text: "70 minutes", value: "70" },
    { text: "75 minutes", value: "75" },
    { text: "80 minutes", value: "80" },
    { text: "85 minutes", value: "85" },
    { text: "90 minutes", value: "90" },
    { text: "95 minutes", value: "95" },
    { text: "100 minutes", value: "100" },
    { text: "105 minutes", value: "105" },
    { text: "110 minutes", value: "110" },
    { text: "115 minutes", value: "115" },
    { text: "120 minutes", value: "120" },
    { text: "125 minutes", value: "125" },
    { text: "130 minutes", value: "130" },
    { text: "135 minutes", value: "135" },
    { text: "140 minutes", value: "140" },
    { text: "145 minutes", value: "145" },
    { text: "150 minutes", value: "150" },
    { text: "155 minutes", value: "155" },
    { text: "160 minutes", value: "160" },
    { text: "165 minutes", value: "165" },
    { text: "170 minutes", value: "170" },
    { text: "175 minutes", value: "175" },
    { text: "180 minutes", value: "180" },
  ],
} as PickerColumn;
