import React, { useState } from "react";
import {
  IonContent,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonButton,
  IonInput,
  IonText,
  IonIcon,
} from "@ionic/react";
import { db, respondToRequest } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { Notif } from "../myTypes";
import { toast } from "../toast";
import { star } from "ionicons/icons";

const Alerts: React.FC = () => {
  const user = useSelector((state: any) => state.user.data);

  const [notifData, setNotifData] = useState<Array<Notif>>([]);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("vendors")
      .doc(user.uid)
      .collection("notifications")
      .orderBy("time", "desc")
      .onSnapshot(function (querySnapshot) {
        let result: Notif[] = [];

        querySnapshot.forEach(function (doc) {
          let data = doc.data() as Notif;
          data.id = doc.id;
          result.push(data);
        });

        setNotifData(result);
      });

    setRenderIndex(renderIndex + 1);
  }
  //

  const [requestNotifsMessage, setRequestNotifsMessage] = useState<string[]>(
    []
  );

  async function respondRequestNotif(
    didAccept: boolean,
    message: string,
    notif: Notif
  ) {
    message = message ? message : "";
    const response = await respondToRequest(didAccept, message, notif);
    if (response) {
      toast("Response sent.", 2000, "success");
    } else {
      toast("Could not send response.", 2000, "danger");
    }
  }

  return (
    <IonContent>
      <IonList>
        <IonListHeader style={{ color: "var(--ion-color-primary)" }}>
          <IonLabel className="eina ion-text-wrap">
            <h2 className="eina">Queue Requests and Feedbacks</h2>
            <p>
              Manage requests by customers to join a queue at your business, and
              view their feedback.
            </p>
          </IonLabel>
        </IonListHeader>
        {notifData.length > 0
          ? notifData.map((notif, key) =>
              notif.type === "request" ? (
                <IonItem
                  key={key}
                  className={!notif.active ? "disabledCard" : ""}
                >
                  <IonLabel className="ion-text-wrap">
                    <IonText
                      color={notif.outcome === "accept" ? "success" : "danger"}
                    >
                      <h4>
                        {!notif.active
                          ? notif.outcome === "accept"
                            ? "Accepted"
                            : "Rejected"
                          : ""}
                      </h4>
                    </IonText>
                    <h3 className="eina">{notif.service.name}</h3>
                    <p style={{ fontSize: "0.7em" }}>
                      {notif.time.toDate().toLocaleString()}
                    </p>
                    <p>
                      Request by <b>{notif.userData.name}</b> (
                      <b>{notif.userData.email}</b>)
                    </p>
                    {notif.active ? (
                      <div>
                        <br />
                        <IonButton
                          color="success"
                          onClick={() =>
                            respondRequestNotif(
                              true,
                              requestNotifsMessage[key],
                              notif
                            )
                          }
                        >
                          Accept
                        </IonButton>
                        <IonButton
                          color="danger"
                          onClick={() =>
                            respondRequestNotif(
                              false,
                              requestNotifsMessage[key],
                              notif
                            )
                          }
                        >
                          Reject
                        </IonButton>
                        <IonItem>
                          <IonInput
                            value={requestNotifsMessage[key]}
                            type="text"
                            placeholder="Leave a message for your customer."
                            onIonChange={(e: any) => {
                              let newArr = requestNotifsMessage;
                              newArr[key] = e.target.value;
                              setRequestNotifsMessage(newArr);
                            }}
                          />
                        </IonItem>
                      </div>
                    ) : null}
                  </IonLabel>
                </IonItem>
              ) : notif.type === "rating" ? (
                <IonItem key={key}>
                  <IonLabel className="ion-text-wrap">
                    <IonText color="primary">
                      <h4>Rating</h4>
                    </IonText>
                    <h3 className="eina">{notif.service.name}</h3>
                    <p style={{ fontSize: "0.7em" }}>
                      {notif.time.toDate().toLocaleString()}
                    </p>
                    <p>
                      <b>{notif.userData.name}</b> (
                      <b>{notif.userData.email}</b>) rated {notif.ratingValue}{" "}
                      stars.
                    </p>
                    <p>
                      {Array(notif.ratingValue).fill(
                        <IonIcon icon={star} color="warning"></IonIcon>
                      )}
                    </p>
                  </IonLabel>
                </IonItem>
              ) : null
            )
          : null}
      </IonList>
    </IonContent>
  );
};

export default Alerts;
