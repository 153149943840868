import React, { useRef } from "react";
import { IonSlide, IonSlides, IonPage, IonContent, IonButton } from "@ionic/react";
import "../theme/slides.css";
import { useHistory } from "react-router";

const Slides: React.FC = () => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const history = useHistory();
  return (
    <IonPage>
      <IonContent>
        <IonSlides pager className="slides" ref={slidesRef}>
          <IonSlide className="signup slide">
            <img alt="" src={require("../images/tour/signup.png")}/>
            
            <h2 className="eina">SignUp within Minutes</h2>
            <p>Register your business in no time, start creating queues for your services and let customers discover you.</p>
            <IonButton color="light" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="services slide">
            <img alt="" src={require("../images/tour/services.png")}/>
            
            <h2 className="eina">Manage Services in a Breeze</h2>
            <p>Quickly add, manage and modify services through a range of powerful features.</p>
            <IonButton color="danger" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="alerts slide">
            <img alt="" src={require("../images/tour/alerts.png")}/>
            
            <h2 className="eina">Real-time Alerts</h2>
            <p>Always stay responsive with live alerts whenever a customer requests to enter your queue, or leaves a feedback.</p>
            <IonButton color="warning" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="queue slide">
            <img alt="" src={require("../images/tour/queue.png")}/>
            
            <h2 className="eina">Manage Queues like a Charm</h2>
            <p>Add online as well as offline customers to your queue, and proceed your queue - all in a few clicks.</p>
            <IonButton color="dark" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="scan slide">
            <img alt="" src={require("../images/tour/scan.png")}/>
            
            <h2 className="eina">Manage Customers like a Pro</h2>
            <p>Simply scan their QR code to view all their booking information.</p>
            <IonButton color="primary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="customer slide">
            <img alt="" src={require("../images/tour/customer.png")}/>
            
            <h2 className="eina">Customer Satisfaction Guaranteed</h2>
            <p>Start with the new trend of Virutal Queues - giving customers the safety they want and the comfort they seek.</p>
            <IonButton color="tertiary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="welcome slide">
            <img alt="" src={require("../images/icon.png")}/>
            
            <h2 className="eina">Start Today</h2>
            <p>What are you waiting for? Start today!</p>
            <IonButton color="success" onClick={() => history.push("/")}>
              Start
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Slides;
