import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfigClient = {
	apiKey: "AIzaSyC0qBlDurJ2mOirFgMBEm-FraUNSeNPlrc",
	authDomain: "local-queue.firebaseapp.com",
	databaseURL: "https://local-queue.firebaseio.com",
	projectId: "local-queue",
	storageBucket: "local-queue.appspot.com",
	messagingSenderId: "49135038954",
	appId: "1:49135038954:web:1a5a00f9368d05d60f4293",
	measurementId: "G-F8VTS4BF13",
};


const firebaseClient = firebase.initializeApp(firebaseConfigClient, "client");
const clientDB = firebaseClient.firestore();

export { clientDB };
